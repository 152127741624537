<template>
  <div class="electronic-signature">
    <div class="signature-service-management">
      <div class="header-box">
        <div class="logo">
          <svg
            class="svgimg"
            aria-hidden="true"
            height="40"
            width="40"
            viewBox="0,0,40,40"
          >
            <use href="#icon-a-bianzu9beifen"></use>
          </svg>
        </div>
        <div class="text">
          <div class="title">
            {{ $t("front-electronic-signature-open-service") }}
          </div>
          <div class="describe">
            {{ $t("front-electronic-signature-describe") }}
          </div>
        </div>
      </div>
      <div class="switch">
        <span>{{
          isOpen
            ? $t("front-electronic-signature-isopen")
            : $t("front-electronic-signature-isclose")
        }}</span
        ><el-switch v-model="isOpen" @change="openChange"> </el-switch>
      </div>
    </div>
    <div class="signature-list" v-show="isOpen">
      <!-- 三个选项卡 -->
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="margin-bottom: 25px"
        >
          <el-menu-item index="1">{{
            $t("front-electronic-signature-service-provider")
          }}</el-menu-item>
          <el-menu-item index="3" v-show="isShow">{{
            $t("front-electronic-signature-setup")
          }}</el-menu-item>
        </el-menu>
        <div v-show="activeIndex === '1'">
          <!-- 法大大卡片-->
          <div class="card">
            <div class="header">
              <div class="logo">
                <img src="@/assets/signature/fadada.svg" alt="" />
                <el-button type="primary" round @click="getFadada" v-if="viewFadada">{{
                  $t("front-electronic-signature-look-account")
                }}</el-button>
              </div>
              <div class="switch">
                <span
                  :style="{ color: !fadadaIsOpen ? '#999999' : '#006DCD' }"
                  >{{
                    fadadaIsOpen
                      ? $t("front-electronic-signature-isopen")
                      : $t("front-electronic-signature-isclose")
                  }}</span
                ><el-switch @change="changeFaDaDa" v-model="fadadaIsOpen">
                </el-switch>
              </div>
            </div>
            <div class="middle">
              <div class="describe">
                <!-- 法大大电子签章服务说明： -->
                <p>{{ $t("front-electronic-signature-explain") }}</p>
                <!-- 绑定电子签章服务前，建议您先在法大大平台进行企业注册和认证。 -->
                <p>
                  1.{{ $t("front-electronic-signature-explain-first") }}
                </p>
                <!-- 进行电子签章绑定时，请先准备好企业营业执照照片和统一社会信用代码。 -->
                <p>
                  2.{{ $t("front-electronic-signature-explain-second") }}
                </p>
              </div>
              <div class="button" v-show="fadadaIsOpen" @click="goToFadada">
                {{ fadadaButton }}
              </div>
            </div>
            <div class="step-line">
              <div
                v-for="(item, index) in flowChartArr"
                :key="index"
                class="box"
              >
                <div class="circle" v-show="item.step > activeFadada">{{ item.step }}</div>
                <div class="circle" v-show="item.step <= activeFadada">
                  <svg style="width: 24px; height: 24px" aria-hidden="true">
                    <use href="#icon-chenggong"></use>
                  </svg>
                </div>
                <div 
                  class="name"
                  :style="{
                    color: item.step <= activeFadada ? '#006dcd' : '#999999',
                  }"
                  >{{ item.name }}</div>
                <div
                :style="{
                    borderColor:
                      item.step <= activeFadada ? '#006dcd' : '#999999',
                    background:
                      item.step <= activeFadada ? '#006dcd' : '#999999',
                  }"
                  class="line"
                  v-show="index != flowChartArr.length - 1"
                ></div>
              </div>
            </div>
          </div>
          <!-- Docusign卡片 -->
          <div class="card">
            <div class="header">
              <div class="logo">
                <img src="@/assets/signature/docusign.svg" alt="" />
                <el-button v-show='docusignButton === $t("label.weixin.unbind")' type="primary" round plain @click="getAccount">{{
                  $t("front-electronic-signature-look-account")
                }}</el-button>
              </div>
              <div class="switch">
                <span
                  :style="{ color: !docusignIsOpen ? '#999999' : '#006DCD' }"
                  >{{
                    docusignIsOpen
                      ? $t("front-electronic-signature-isopen")
                      : $t("front-electronic-signature-isclose")
                  }}</span
                ><el-switch @change="changeDocuSign" v-model="docusignIsOpen">
                </el-switch>
              </div>
            </div>
            <div class="middle">
              <div class="describe">
                <p>{{ $t("front-electronic-signature-explainDocusign") }}</p>
                <p>1.{{ $t("front-electronic-signature-setting-intro1") }}</p>
                <p>2.{{ $t("front-electronic-signature-setting-intro2") }}</p>
              </div>
              <div class="button" v-show="docusignIsOpen" @click="goToDocuSign">
                {{ docusignButton }}
              </div>
            </div>
            <div class="step-line">
              <div
                v-for="(item, index) in flowChartArr2"
                :key="index"
                class="box"
              >
                <div class="circle" v-show="item.step > activeFlowChart">
                  {{ item.step }}
                </div>
                <div class="circle" v-show="item.step <= activeFlowChart">
                  <svg style="width: 24px; height: 24px" aria-hidden="true">
                    <use href="#icon-chenggong"></use>
                  </svg>
                </div>
                <div
                  class="step-name"
                  :style="{
                    color: item.step <= activeFlowChart ? '#006dcd' : '#999999',
                  }"
                >
                  {{ item.name }}
                </div>
                <div
                  :style="{
                    borderColor:
                      item.step <= activeFlowChart ? '#006dcd' : '#999999',
                    background:
                      item.step <= activeFlowChart ? '#006dcd' : '#999999',
                  }"
                  class="lineStep"
                  v-if="index != flowChartArr2.length - 1"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activeIndex === '3'" class="templateBox">
          <div v-show="docusignIsOpen">
              <div class="buttonArea">
              <el-button-group>
                <el-button
                  @click="changeCheckedButton('0')"
                  :type="checkedButton === '0' ? 'primary' : ''"
                  >{{
                    $t("front-electronic-signature-auto-fill-field")
                  }}</el-button
                >
                <el-button
                  @click="changeCheckedButton('1')"
                  :type="checkedButton === '1' ? 'primary' : ''"
                  >{{
                    $t("front-electronic-signature-backfill-field")
                  }}</el-button
                >
              </el-button-group>
            </div>
            <div class="template">
              <el-button style="margin-bottom: 20px" @click="addField">{{
                $t("front-electronic-signature-field-mapping")
              }}</el-button>
              <el-table
                :empty-text="$t('label.weixin.nomore')"
                ref="multipleTable"
                max-height="400"
                :data="templateList"
                :header-cell-style="{ background: '#F0F0F0', color: '#333333' }"
              >
                <el-table-column
                  :prop="item.name"
                  v-for="(item, index) in headList"
                  :key="index"
                  :label="item.fieldLabel"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <div v-if="item.id === '009'">
                      <span
                        style="color: #006dcc; cursor: pointer"
                        @click="deleteSign(scope.row)"
                        >{{ $t("label.chat.topic.delete") }}</span
                      >
                    </div>
                    <div v-else>{{ scope.row[item.name] }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div v-show="fadadaIsOpen">
            <div class="buttonArea">
              <el-button-group>
                <!-- 获取自动填充字段 -->
                <el-button
                type="primary"
                  @click="changeCheckedBtn">{{
                    $t("front-electronic-signature-auto-fill-field")   
                  }}</el-button
                >
              </el-button-group>
            </div>
            <div class="template">
              <el-button style="margin-bottom: 20px" @click="addFieldBtn">{{
                $t("front-electronic-signature-field-mapping")
              }}</el-button>
              <el-table
                :empty-text="$t('label.weixin.nomore')"
                ref="multipleTable"
                max-height="400"
                :data="templateLists"
                :header-cell-style="{ background: '#F0F0F0', color: '#333333' }"
              >
                <el-table-column
                  :prop="item.name"
                  v-for="(item, index) in headLists"
                  :key="index"
                  :label="item.fieldLabel"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <div v-if="item.id === '009'">
                      <span
                        style="color: #006dcc; cursor: pointer"
                        @click="deleteFadada(scope.row)"
                        >{{ $t("label.chat.topic.delete") }}</span
                      >
                    </div>
                    <div v-else>{{ scope.row[item.name] }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <addContact/> -->
    <!-- 添加字段映射弹窗 -->
    <div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item :label="$t('component.setup.label.object')" prop="name">
            <el-select v-model="ruleForm.name" placeholder="">
              <el-option
                :label="$t('vue_label_normal_contract_sf')"
                :value="$t('vue_label_normal_contract_sf')"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.tabpage.field')" prop="region">
            <el-select
              v-model="ruleForm.region"
              placeholder=""
              @change="changeField"
            >
              <el-option
                :label="item.labelName"
                :value="item.id"
                v-for="(item, index) in contactData.fieldList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="false" :label="$t('label.field.type')" prop="type">
            <el-input :disabled="true" v-model="fieldListType"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('setup.mainpage.label.welcome5')"
            prop="date1"
          >
            <el-select
              v-model="ruleForm.date1"
              placeholder=""
              @change="changeFieldT"
            >
              <el-option
                :label="item.tabLabel"
                :value="item.customTabId"
                v-for="(item, index) in this.contactData.fieldSetList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="false"
            :label="$t('front-electronic-signature-custom-field-type')"
            prop="date2"
          >
            <el-input :disabled="true" v-model="fieldSetListType"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.description')" prop="resource">
            <el-input v-model="ruleForm.resource"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <!-- 确定 -->
          <el-button type="primary" @click="saveContact" :lodding="btLodding"
            >{{ $t("label.tabpage.ok") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 法大大添加字段映射弹窗 -->
    <div>
      <el-dialog :title="$t('front-electronic-signature-add-autofill-field')" :visible.sync="dialogFadada" width="30%">
        <el-form
          :model="ruleForms"
          :rules="rules"
          ref="ruleForms"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
        <!-- 模板 -->
          <el-form-item label="模板" prop="templatedoc">
            <el-select v-model="ruleForms.templatedoc" @change="changetemplate" placeholder="">
              <el-option
                :label="item.signTemplateName"
                :value="item.signTemplateId"
                v-for="(item, index) in getField.templatedoc"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 对象 -->
          <el-form-item :label="$t('component.setup.label.object')" prop="name">
            <el-select v-model="ruleForms.name" placeholder="">
              <el-option
                :label="$t('vue_label_normal_contract_sf')"
                :value="$t('vue_label_normal_contract_sf')"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 字段 -->
          <el-form-item :label="$t('label.tabpage.field')"  prop="region">
            <el-select
              v-model="ruleForms.region"
              placeholder=""
              @change="ChangeFieldType"
            >
              <el-option
                :label="item.labelName"
                :value="item.id"
                v-for="(item, index) in getField.fieldList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 字段类型 -->
          <el-form-item v-show="false" :label="$t('label.field.type')" prop="type">
            <el-input :disabled="true" v-model="fieldT"></el-input>
          </el-form-item>
          <!-- 自定义字段 -->
          <el-form-item
            :label="$t('setup.mainpage.label.welcome5')"
            prop="date1"
          >
            <el-select
              v-model="ruleForms.date1"
              placeholder=""
             @change="changeCustomFieldType"
            >
              <el-option
                :label="item.fieldName"
                :value="item.fieldId"
                v-for="(item, index) in getField.customField"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 自定义字段类型 -->
          <el-form-item
            v-show="false"
            :label="$t('front-electronic-signature-custom-field-type')"
            prop="date2"
          >
            <el-input :disabled="true" v-model="customFieldType"></el-input>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item :label="$t('label.description')" prop="resource">
            <el-input v-model="ruleForms.resource"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFadada = false">{{
            $t("label.cancel")
          }}</el-button>
          <!-- 确定 -->
          <el-button type="primary" @click="saveContacts(ruleForms)" :lodding="btLodding"
            >{{ $t("label.tabpage.ok") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 法大大解除绑定弹窗 -->
    <el-dialog
      :title="dialogtitle"
      :visible.sync="dialogfadada"
      width="30%"
    >
    <!-- 解绑后将无法使用法大大电子签章功能，已设置好的自动填充和回填字段关系也将被删除，是否确定解绑账号？ -->
      <span
        class="confirmInfo"
        >{{$t('c837')}}</span
      >
      <span slot="footer" class="dialog-footer">
          <!-- 取消 -->
        <el-button @click="dialogfadada=false">{{
          $t("label.cancel")
        }}</el-button>
        <!-- 确认 -->
        <el-button
        :loading="confirmLoadingF"
          type="primary"
          @click="unbound"
          >{{
          $t("label.emailtocloudcc.button.sure")
        }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 解除绑定弹窗 -->
    <el-dialog
      :title="dialogtitle"
      :visible.sync="dialogVisibleSecond"
      width="30%"
    >
    <!-- 解绑后将无法使用DocuSign电子签章功能，是否确定解绑账号？ -->
      <span
        v-show="docusignButton === $t('label.weixin.unbind')"
        class="confirmInfo"
        >{{ $t("front-electronic-signature-cancelIntro1") }}</span
      >
      <!-- 如果您是进行第1次账号绑定，请点击继续进入到下一步；如果您是解绑后需要重新绑定，建议点击取消按钮，清除浏览器缓存后再进行绑定。 -->
      <!-- 绑定账号 -->
      <span v-show="docusignButton === $t('front-electronic-signature-open-confirm')" class="confirmInfo"
        >{{ $t("front-electronic-signature-cancelIntro2") }}</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSecond = false">{{
          $t("label.cancel")
        }}</el-button>
        <!-- 绑定账号 -->
        <el-button
          :loading="confirmLoading"
          type="primary"
          @click="confirmCancel"
          >{{
            docusignButton === $t('front-electronic-signature-open-confirm')
              ? $t("label.goon")
              : $t("label.confirm")
          }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看账号信息弹窗 -->
    <el-dialog
      :title="dialogtitle"
      :visible.sync="dialogVisibleThird"
      width="450px"
    >
      <div class="infoItem">
        <span>{{ $t("label.partnerCloud.partnerAccount.account") }}</span>
        <el-input readonly v-model="userInfo"></el-input>
      </div>
      <div class="infoItem">
        <span>{{ $t("label.emailsync.form.email") }}</span>
        <el-input readonly v-model="emailInput"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleThird = false">{{
          $t("label.tabpage.closez")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogtitle"
      :visible.sync="seeDialogVisible"
      width="450px"
    >
      <div>
        <div class="infoItem">
          <span>{{$t('label.tianyancha.name')}}</span>
          <el-input readonly v-model="enterpriseName"></el-input>
        </div>
        <div class="infoItem">
          <span>{{$t('c838')}}</span>
          <el-input readonly v-model="handler"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seeDialogVisible = false">{{
          $t("label.tabpage.closez")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as docusignApi from "../../systemSettingsApi.js";
export default {
  data() {
    return {
      enterpriseName:'',
      confirmLoading: false,
      confirmLoadingF:false,
      userInfo: "",
      openObj: {}, //窗口对象
      checkedButton: "0",
      dialogTitle: this.$i18n.t(
        "front-electronic-signature-add-autofill-field"
      ),
      dialogtitle: this.$i18n.t('front-electronic-signature-open-confirm'),        //绑定账号
      dialogVisible: false,
      dialogVisibleSecond: false,
      dialogVisibleThird: false,
      ruleForm: {
        name: this.$i18n.t("vue_label_normal_contract_sf"),
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: "",
        resource: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$i18n.t("front-electronic-signature-enter-object"),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("label.processsetting.please.select.field"),
            trigger: "change",
          },
        ],
        date1: [
          {
            required: true,
            message: this.$i18n.t("front-docusign-setting-error-tips"),
            trigger: "change",
          },
        ],
      },
      templateList: [],
      headList: [
        {
          id: "003",
          fieldLabel: this.$i18n.t("component.setup.label.object"),
          name: "object",
          isshow: true,
          isAsc: false,
          fieldapi: "object",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.tabpage.field"),
          name: "fieldName",
          isshow: true,
          isAsc: false,
          fieldapi: "fieldName",
        },
        // {
        //   id: "003",
        //   fieldLabel: this.$i18n.t("label.field.type"),
        //   name: "fieldType",
        //   isshow: true,
        //   isAsc: false,
        //   fieldapi: "fieldType",
        // },
        {
          id: "003",
          fieldLabel: this.$i18n.t("setup.mainpage.label.welcome5"),
          name: "customName",
          isshow: true,
          isAsc: false,
          fieldapi: "customName",
        },
        // {
        //   id: "003",
        //   fieldLabel: this.$i18n.t(
        //     "front-electronic-signature-custom-field-type"
        //   ),
        //   name: "customType",
        //   isshow: true,
        //   isAsc: false,
        //   fieldapi: "customType",
        // },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.description"),
          name: "remark",
          isshow: true,
          isAsc: false,
          fieldapi: "remark",
        },
        {
          id: "009",
          fieldLabel: this.$i18n.t("label.ems.action"),
          name: "",
          isshow: true,
          isAsc: false,
          fieldapi: "",
        },
      ],
      headLists: [
        {
          id: "003",
          fieldLabel: '模板',
          name: "templatename",
          isshow: true,
          isAsc: false,
          fieldapi: "templatename",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("component.setup.label.object"),
          name: "object",
          isshow: true,
          isAsc: false,
          fieldapi: "object",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.tabpage.field"),
          name: "fieldName",
          isshow: true,
          isAsc: false,
          fieldapi: "fieldName",
        },
        // {
        //   id: "003",
        //   fieldLabel: this.$i18n.t("label.field.type"),
        //   name: "fieldType",
        //   isshow: true,
        //   isAsc: false,
        //   fieldapi: "fieldType",
        // },
        {
          id: "003",
          fieldLabel: this.$i18n.t("setup.mainpage.label.welcome5"),
          name: "customName",
          isshow: true,
          isAsc: false,
          fieldapi: "customName",
        },
        // {
        //   id: "003",
        //   fieldLabel: this.$i18n.t(
        //     "front-electronic-signature-custom-field-type"
        //   ),
        //   name: "customType",
        //   isshow: true,
        //   isAsc: false,
        //   fieldapi: "customType",
        // },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.description"),
          name: "remark",
          isshow: true,
          isAsc: false,
          fieldapi: "remark",
        },
        {
          id: "009",
          fieldLabel: this.$i18n.t("label.ems.action"),
          name: "",
          isshow: true,
          isAsc: false,
          fieldapi: "",
        },
      ],
      activeIndex: "1",
      //电子签章服务是否开启
      isOpen: false,
      //法大大签章服务是否开启
      fadadaIsOpen: false,
      fadadaButton: "",       // 绑定法大大电子签账号
      //流程图数组
      flowChartArr: [
        { step: 1, name: this.$i18n.t('front-electronic-signature-open-confirm') },      // 绑定账号
        { step: 2, name: this.$i18n.t('c831') },      // 认证中
        { step: 3, name: this.$i18n.t('c832') },      // 审核中
        { step: 4, name: this.$i18n.t('c834') },      // 初审通过
        { step: 5, name: this.$i18n.t('c833') },      // 认证通过
      ],
      //Docusign签章服务是否开启
      docusignIsOpen: false,
      docusignButton: "",
      //流程图数组
      flowChartArr2: [
        { step: 1, name: this.$i18n.t('front-electronic-signature-open-confirm') },        // 绑定账号
        { step: 2, name: this.$i18n.t('c40') },      // 输入账号密码
        { step: 3, name: this.$i18n.t('front-electronic-signature-open-vertify') },        // 输入验证码  front-electronic-signature-open-vertify
        { step: 4, name: this.$i18n.t("label.mobile.bind.success") },
      ],
      activeFlowChart: 0,
      contactData: [], //映射from数据
      btLodding: false,
      fieldListType: "",
      fieldSetListType: "",
      viewFadada:false,//法大大是否显示查看账号按钮
      dialogfadada:false,//解除绑定法大大弹框
      activeFadada:0,//法大大流程图样式
      dialogFadada:false,//法大大添加字段映射按钮
      openUrl:{},
      ruleForms:{
        templatedoc:'',
        name: this.$i18n.t("vue_label_normal_contract_sf"),
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: "",
        resource: "",
      },
      templateLists:[],
      getField:{ fieldList:[],customField:[],templatedoc:[]},//映射字段
      fieldT:'',//合同字段类型
      customFieldType:'',//自定义字段字段类型
      emailInput:'',//企业名称
      handler:'',//经办人
      seeDialogVisible:false,
      isShow:false,
    };
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, true);
    this.initList();
    this.getElectronicStatus();
},
  destroyed() {
    window.removeEventListener("message", this.receiveMessage, true);
  },
  methods: {
    async getAccount() {
      this.dialogtitle = this.$i18n.t(
        "front-electronic-signature-look-account"
      );
      this.dialogVisibleThird = true;
      let result = await docusignApi.getUserInfo({});
      this.emailInput = result.data.email;
      this.userInfo = result.data.name;
    },
    //
    changeField() {
      this.contactData.fieldList.map((item) => {
        if (item.id === this.ruleForm.region) {
          this.fieldListType = item.schemefieldTypeName;
        }
      });
    },
    changeFieldT() {
      this.contactData.fieldSetList.map((item) => {
        if (item.customTabId === this.ruleForm.date1) {
          this.fieldSetListType = item.type;
        }
      });
    },
    openChange(val) {
      if (!val) {
        this.updateElectronicContractStatus("0", "0");
        this.docusignIsOpen = false;
        this.fadadaIsOpen = false;
      } else {
        this.updateElectronicContractStatus("1", "0");
      }
    },
    //修改设定状态
    updateElectronicContractStatus(status, serviceProvider) {
      docusignApi
        .updateElectronicContractStatus({ status, serviceProvider })
        .then(() => {
          this.getElectronicStatus();
        });
    },
    //删除状态
    deleteSign(row) {
      docusignApi.deleteCustomField({ id: row.id }).then(() => {
        this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
        this.initList();
      });
    },
    initList() {
      docusignApi.getCustomField({ type: this.checkedButton }).then((res) => {
        res.data.map((item) => {
          item.object = this.$i18n.t("vue_label_normal_contract_sf");
        });
        this.templateList = res.data;
      });
    },
    saveContact() {
      this.$refs.ruleForm.validate((valid) => {
        this.btLodding = true;
        if (valid) {
          let obj = {
            field_name: this.ruleForm.region.labelName,
            field_id: this.ruleForm.region.id,
            field_type: this.ruleForm.region.schemefieldType,
            custom_name: this.ruleForm.date1.tabLabel,
            custom_id: this.ruleForm.date1.customTabId,
            custom_type: this.ruleForm.date1.type,
            remark: this.ruleForm.resource,
            type: this.checkedButton,
          };  
          docusignApi.createCustomField(obj).then(() => {
            this.btLodding = false;
            this.dialogVisible = false;
            this.$message.success(this.$i18n.t('front-electronic-signature-save-success'));        // 映射关系保存成功
            this.ruleForm = {
              name: this.$i18n.t("vue_label_normal_contract_sf"),
              region: "",
              date1: "",
              date2: "",
              delivery: false,
              type: "",
              resource: "",
            };
            this.initList();
          });
        } else {
          return false;
        }
      });
    },
    // 确认解除绑定
    confirmCancel() {
      if (this.docusignButton === this.$i18n.t("label.weixin.unbind")) {
        this.confirmLoading = true;
        docusignApi.revokeAuthorization().then((res) => {
          this.confirmLoading = false;
          this.dialogVisibleSecond = false;
          if (res.returnCode === "1") {
            this.$message.success(this.$i18n.t('c41'));      // 解绑成功
            this.docusignButton = this.$i18n.t('front-electronic-signature-open-confirm');       // 绑定账号
            this.activeFlowChart = 0; 
            this.getElectronicStatus();
          }
        });
      } else if (this.docusignButton === this.$i18n.t('front-electronic-signature-open-confirm')) {
        // 绑定账号
        this.confirmLoading = true;
        //获取授权码
        docusignApi.getAuthorizationCode().then((res) => {
          this.openObj = window.open(res.data);
        });
      }
    },
    // 获取签署设定状态
    getElectronicStatus() {
      docusignApi.getElectronicContractStatus().then((res) => {
        if (res.data.status === "0") {
          this.isOpen = false;
          this.activeFadada=0//法大大进度条
        } else if (res.data.status === "1") {
          this.isOpen = true;
          if (res.data.serviceProvider === "0") {
            this.fadadaIsOpen = false;
            this.docusignIsOpen = false;
          } else if (res.data.serviceProvider === "1") {
            this.fadadaIsOpen = true;
            this.docusignIsOpen = false;
            if (res.data.authstatus == "1") {
              this.isShow=true;//合同信息填充tab
              this.viewFadada=true;//显示查看账号
              this.fadadaButton = this.$i18n.t('label.weixin.unbind');     //解除绑定
              this.activeFadada = 5;
            } else {
              this.isShow=false;//合同信息填充tab
              this.fadadaButton = this.$i18n.t('c836');       // 绑定法大大电子签账号
              this.activeFadada = 0;
            }
          } else if (res.data.serviceProvider === "2") {
            this.fadadaIsOpen = false;
            this.docusignIsOpen = true;
            if (res.data.authstatus === "1") {
              this.isShow=true;//合同信息填充tab
              this.docusignButton = this.$i18n.t("label.weixin.unbind");
              this.activeFlowChart = 4;
            } else if (res.data.authstatus !== "1") {
              this.docusignButton = this.$i18n.t('front-electronic-signature-open-confirm');       // 绑定账号
              this.activeFlowChart = 0;
            }
          }
        }
        this.$nextTick(() => {
          // 获取 4个 文本区域宽度
          let textWidth = 0;
          let domArray = document.getElementsByClassName("step-name");
          domArray = Array.from(domArray);
          domArray.forEach((element) => {
            textWidth += element.offsetWidth;
          });
          let lineWidth = Math.floor((996 - textWidth) / 3) - 60;
          // 将step-line的宽度设置为lineWidth
          Array.from(document.getElementsByClassName("lineStep")).forEach(
            (element) => {
              element.style.width = lineWidth + "px";
            }
          );
          let line = Math.floor((996 - textWidth) / 4) - 60;
          // 将step-line的宽度设置为line
          Array.from(document.getElementsByClassName("line")).forEach(
            (element) => {
              element.style.width = line + "px";
            }
          );
        });
      });
    },
    // 解绑企业
    unbound() {
      docusignApi.corpDelete().then((res)=>{
        if (res.returnCode === "1"){
          this.dialogfadada=false
          this.$message.success(this.$i18n.t('c41'));
          this.viewFadada=false;//隐藏查看账号
          this.activeFadada=0;//流程图置灰
          this.fadadaButton=this.$i18n.t('c836');//字样变为绑定法大大电子签账号
        }
      });
    },
    init() {
      // 获取字段
      docusignApi.getTabDefinitions().then((res) => {
        this.contactData.fieldSetList = res.data.tabs;
      });
      // 获取合同字段
      docusignApi.getContractField().then((res) => {
        this.contactData.fieldList = res.data;
      });
    },
    receiveMessage(event) {
      //docusign
      if (event.data.includes("https://testintegration.cloudcc.com/api/docusign/") || event.data.includes("https://integration.cloudcc.com/api/docusign/")) {
        let code = event.data.replace(
          "https://testintegration.cloudcc.com/api/docusign/redirectUrl?code=",
          ""
        );
        //  获取访问令牌
        docusignApi.getAccessToken({ code: code }).then((res) => {
          if (res.result) {
            this.docusignButton = this.$i18n.t("label.weixin.unbind");
            this.activeFlowChart = 4;
            this.$message.success(this.$i18n.t('front-electronic-signature-confirmintro'));        // 绑定成功。您可以在合同对象直接发起签署
            this.getElectronicStatus();
          }
        });
        this.openObj.close();
        this.dialogVisibleSecond = false;
      }
      // fadada
      if (event.data.includes("https://testintegration.cloudcc.com/api/fadada/") || event.data.includes("https://integration.cloudcc.com/api/fadada/")) {
        if(event.data.split("&")[1] == "authResult=success"){
          //更新法大大设定状态
          let data={
            status:1,
            serviceProvider:1,
            authstatus:1
          }
          docusignApi.updateElectronicStatus(data).then(()=>{
            this.getElectronicStatus();
          })
          this.fadadaButton=this.$i18n.t('label.weixin.unbind');
          this.activeFadada=5;//流程图置蓝
          this.viewFadada=true;//显示查看账号
          this.openUrl.close();//
          // this.getElectronicStatus();
        }else if(event.data.split("&")[1] == "authResult=fail"){
           this.$message.success(this.$i18n.t('label.mobile.bind.fail'));
           this.activeFadada=0
        }
      }
    },
    // 绑定docusign账户
    goToDocuSign() {
      if (this.docusignButton === this.$i18n.t('front-electronic-signature-open-confirm')) {       // 绑定账号
        // 弹窗提示
        this.dialogtitle = this.$i18n.t("label_popup_infoz");
        this.dialogVisibleSecond = true;
      } else if (this.docusignButton === this.$i18n.t("label.weixin.unbind")) {
        this.dialogVisibleSecond = true;
      }
    },
    changeCheckedButton(type) {
      this.checkedButton = type;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
      if (this.checkedButton === "0") {
        this.dialogTitle = this.$i18n.t(
          "front-electronic-signature-add-autofill-field"
        );
      } else {
        this.dialogTitle = this.$i18n.t('front-electronic-signature-add-field');      // 添加回填字段
      }
      this.initList();
    },
    changeFaDaDa(val) {
      if (val && this.docusignIsOpen) {
        this.fadadaIsOpen = false;
        this.$message.warning(
          this.$i18n.t('front-electronic-signature-open-signInfo1')      // 您已开启DocuSign电子签章服务，请关闭后，再开启法大大电子签章服务
        );
      } else if (val && !this.docusignIsOpen) {
        this.updateElectronicContractStatus("1", "1");
      } else {
        //关闭fadada
        this.updateElectronicContractStatus("1", "0");
      }
    },
    changeDocuSign(val) {
      if (val && this.fadadaIsOpen) {
        this.docusignIsOpen = false;
        this.$message.warning(
          this.$i18n.t('front-electronic-signature-open-signInfo2')    // 您已开启法大大电子签章服务，请关闭后，再开启DocuSign电子签章服务
        );
        //开启docusign 且法大大未开启
      } else if (val && !this.fadadaIsOpen) {
        this.updateElectronicContractStatus("1", "2");
      } else {
        //关闭docusign
        this.updateElectronicContractStatus("1", "0");
      }
    },
    addField() {
      this.dialogVisible = true;
    },

    goToFadada() {
      if(this.fadadaButton==this.$i18n.t('c836')){
              // 获取法大大授权
        docusignApi.getAuthUrl().then((res)=>{
          if(res.returnCode === "1"){
                this.openUrl=window.open(res.data.eUrl)//进入【企业认证和注册页】
              }
        })
      }else if(this.fadadaButton==this.$i18n.t('label.weixin.unbind')){
          this.dialogfadada=true//解除绑定弹框
      }
      
    },
    //删除字段填充
    deleteFadada(row){
      docusignApi.deleteCustomFieldFadada({ id: row.id }).then(() => {
        this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
        this.changeCheckedBtn();
      });
    },
    // 点击自动填充字段按钮
    changeCheckedBtn(){
       docusignApi.getCustomFieldFadada({ type: 0 }).then((res) => {
        this.templateLists=res.data
      })
    },
    //添加字段映射按钮
    addFieldBtn(){
      this.dialogFadada=true;
      this.$refs.ruleForms.resetFields();
      this.fieldT=''
      this.customFieldType=''
    },
    getFieldInit(){
      // 获取模板列表
      docusignApi.getTempalteList().then((res)=>{
        this.getField.templatedoc=res.data.signTempalteList
      })
      this.changeCheckedBtn()
    },
    // 选择模板类型 获取模板中的自定义控件列表
    changetemplate(val){
       docusignApi.getContractFieldFadada({
         templateId: val
       }).then((res)=>{
         if(res.data){
          // 字段
          this.getField.fieldList=res.data.contractFields||[]
          // 自定义字段
          this.getField.customField = res.data.templateFields||[]
         }
        
      })
    },
    // 选择字段 得到字段的字段类型
    ChangeFieldType(val){
      this.getField.fieldList.map(item=>{
        if(item.id === val){
          this.fieldT = item.schemefield_type
        }
      })
    },
    // 选择自定义字段 得到自定义字段 字段类型
    changeCustomFieldType(val){
      this.getField.customField.map(item=>{
        if(item.fieldId === val){
          this.customFieldType = item.fieldType
        }
      })
    },
    // 添加自动填充字段保存
    saveContacts(val){
      let tem=this.getField.templatedoc.find(item=>item.signTemplateId==val.templatedoc)
      let fie=this.getField.fieldList.find(item=>item.id==val.region)
      let cus=this.getField.customField.find(item=>item.fieldId==val.date1)
      this.$refs.ruleForms.validate((valid) => {
        this.confirmLoadingF=true
        if(valid){
          let data={
            template_id:tem.signTemplateId,
            template_name:tem.signTemplateName,
            doc_id:`${tem.docs[0].docId}`,
            object:'contract',
            field_name:fie.labelName,
            field_id: fie.id,
            field_type: fie.schemefieldType,
            custom_name: cus.fieldName,
            custom_id: cus.fieldId,
            custom_type: cus.fieldType,
            remark: this.ruleForms.resource,
            type: 0,
          }
          docusignApi.createCustomFieldFadada(data).then((res)=>{
            this.confirmLoadingF=false
            if(res.returnCode=="1"){
              this.$message.success(this.$i18n.t('vue_label_notice_add_success'))
              this.changeCheckedBtn();
            }
            this.dialogFadada=false
            this.ruleForms={
              templatedoc:'',
              name: this.$i18n.t("vue_label_normal_contract_sf"),
              region: "",
              date1: "",
              date2: "",
              delivery: false,
              type: "",
              resource: "",
            }
          })
        }
      })
      
    },
    //法大大查看账号
    getFadada(){
      this.dialogtitle = this.$i18n.t(
        "front-electronic-signature-look-account"
      );
      this.seeDialogVisible = true;
      docusignApi.getIdentityInfo().then((res)=>{
        this.enterpriseName = res.data.nameInfo.corpName;
        this.handler=res.data.nameInfo.userName
      })
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.fieldListType = "";
        this.fieldSetListType = "";
      }
    },
    activeIndex(val) {
      if (val === "3"&& this.docusignButton== this.$i18n.t('label.weixin.unbind')) {
        this.init();
      }
      if (val === "3" && this.fadadaButton==this.$i18n.t('label.weixin.unbind')) {
       this.getFieldInit();//获取添加自动填充字段弹框中的模板列表和合同对象字段
      }
    },
    dialogVisibleSecond(val) {
      if (val) {
        this.confirmLoading = false;
      }
    },
  },
  components: {
    // addContact
  },
};
</script>

<style lang='scss' scoped>
.infoItem {
  margin-bottom: 10px;
  span{
    height:40px;
    display: inline-block;
    line-height: 40px;
  }
  ::v-deep .el-input {
    width: 300px;
    float: right;
  }
}
.emailDialog {
  .el-input {
    width: 90%;
  }
}
::v-deep .el-input__suffix {
  line-height: 40px;
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
.confirmInfo {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: solid 2px #006dcd;
  height: 44px;
}
::v-deep .el-button--primary {
  background-color: #006dcd;
  border-color: #006dcd;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  background: #006dcd !important;
  color: #ffffff !important;
  height: 44px;
  border: none;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  height: 44px;
  line-height: 44px;
}
.electronic-signature {
  padding: 24px 24px 0px 24px;
  .header-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .signature-service-management {
    width: 100%;
    height: 128px;
    background: #f3f8fe;
    border-left: 2px solid #006dd3;
    display: flex;
    align-items: center;
    padding: 0 200px 0 67px;
    margin-bottom: 36px;
    justify-content: space-between;
    .logo {
      margin-right: 69px;
      .svgimg {
        width: 89px;
        height: 79px;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: nowrap;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 12px;
      }
      .describe {
        background: #f3f8fe;
      }
    }
    .switch {
      span {
        margin-right: 10px;
      }
    }
  }
  .signature-list {
    .templateBox {
      width: 100%;
      height: auto;
      padding: 0px 20px 20px 0px;
      .buttonArea {
        margin-bottom: 24px;
      }
      .template {
        width: 100%;
        background: #f5f5f5;
        padding: 16px;
      }
    }
    .card {
      width: 1102px;
      height: 234px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 24px 30px;
      margin-bottom: 24px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        .logo {
          display: flex;
          align-items: center;
          ::v-deep .el-button.is-round {
            padding: 10px 17px;
            font-size: 12px;
            margin-left: 15px;
          }
        }
        img {
          width: 130px;
          height: 60px;
        }
        .switch {
          span {
            margin-right: 10px;
          }
        }
      }
      .middle {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .button {
        width: 200px;
        height: 36px;
        background: #006dcd;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        line-height: 36px;
        text-align: center;
      }
      .step-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        color: #999999;
        .box {
          display: flex;
          align-items: center;
        }
        .circle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #eeeeee;
          line-height: 24px;
          text-align: center;
        }
        .step-name {
          margin-left: 10px;
          white-space: nowrap;
        }
        .lineStep {
          height: 2px;
          border: 1px solid #999999;
          margin: 0 10px;
        }
        .line {
          height: 2px;
          border: 1px solid #999999;
          margin: 0 10px;
        }
        .name {
          margin-left: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
::v-deep .el-table .cell div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-form-item__label {
  line-height: 15px;
}
::v-deep .el-select {
  width: 100%;
}
</style>